import React from "react"
import { graphql } from "gatsby"

import {
  BulletListWithIcon,
  CheckatradeInfo,
  Faq,
  Layout,
  Seo,
  HeaderBlob,
} from "../components/Shared"
import { GoogleReviews, ThermalImaging } from "../components/Sections"

const localService = ({ data, location }) => {
  const [visibleForm, setVisibleForm] = React.useState("")
  const localService = data.localService

  React.useEffect(() => {
    if (data.localService.forms && data.localService.forms.length > 0) {
      setVisibleForm(data.localService.forms[0].contentful_id)
    }
    return () => {
      setVisibleForm("")
    }
  }, [])
  return (
    <Layout>
      <Seo
        url={location.href}
        title={localService?.seo?.title}
        description={localService?.seo?.metaDescription?.metaDescription}
        image={localService?.seo?.image?.file?.url}
      />
      <HeaderBlob
        image={localService.photo.fluid}
        imageDescription={localService.photo.title}
      >
        <h1 className="heading-extra-large">{localService.headerTitle}</h1>
        <BulletListWithIcon bullets={localService.headerBullets} />
        <CheckatradeInfo />
      </HeaderBlob>
      <a href="tel: 0191 406 0888" className="service-contact">
        <div className="wrapper-width">
          <span className="service-contact-heading">Call us now</span>
          <span className="service-contact-number">0191 406 0888</span>
        </div>
      </a>
      <GoogleReviews />
      {localService?.forms && localService?.forms?.length > 0 && (
        <section className="wrapper-width wrapper-height">
          <h2 className="heading-large sub-service-forms-heading">
            Book today
          </h2>
          <div className="sub-service-forms">
            {localService?.forms?.length > 1 && (
              <header className="sub-service-forms-header">
                {localService.forms.map((form) => (
                  <button
                    key={form.contentful_id}
                    className={`sub-service-forms-header-button 
                    ${
                      form.contentful_id === visibleForm
                        ? "button-primary"
                        : "button-secondary"
                    }
                  `}
                    onClick={() => setVisibleForm(form.contentful_id)}
                  >
                    {form.name}
                  </button>
                ))}
              </header>
            )}
            <div className="sub-service-forms-body">
              {localService.forms.map((form) => (
                <div
                  key={form.contentful_id}
                  className={`sub-service-forms-body-form ${
                    visibleForm === form.contentful_id
                      ? " sub-service-forms-body-form-active"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: form.iframe.iframe }}
                />
              ))}
            </div>
          </div>
        </section>
      )}
      {localService.faqs && localService.faqs.length > 0 && (
        <section className="wrapper-width wrapper-height faq-section service-faqs">
          <h2 className="heading-large">Frequently asked questions</h2>
          <div className="faqs">
            {localService.faqs.map((faq) => (
              <Faq key={faq.id} question={faq.question}>
                {faq.answer.answer}
              </Faq>
            ))}
          </div>
        </section>
      )}
      <ThermalImaging />
    </Layout>
  )
}

export default localService

export const pageQuery = graphql`
  query getLocalServicePageDetails($id: String!) {
    localService: contentfulServicePagesSubServicePagesLocalService(
      contentful_id: { eq: $id }
    ) {
      id
      headerBullets
      headerTitle
      name
      photo {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid
        }
        title
      }
      slug
      faqs {
        answer {
          answer
        }
        question
        id
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`
